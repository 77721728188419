/* eslint-disable import/prefer-default-export */

// Production URLS
export const BASE_URL = "https://api.credentials.libt.co.uk";
export const APP_URL = "https://credentials.libt.co.uk";

// Staging URLS
//export const BASE_URL = "https://stg.api.credentials.libt.co.uk";
//export const APP_URL = 'https://stg.credentials.libt.co.uk';

//Localhost
// export const BASE_URL = "http://localhost:3000";
// export const APP_URL = 'http://localhost:3002'
